@import url(https://use.typekit.net/bck7fxt.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* * {
  border: red solid 1px;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* @import url("https://fonts.cdnfonts.com/css/trebuchet-ms-2?styles=14872,30108"); */

.App {
}

@media (prefers-reduced-motion: no-preference) {
}

